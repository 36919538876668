import React from "react";
import styles from "./NavBar.module.css";
import ThemeToggle from "./ThemeToggle";
import { useTheme } from "./ThemeContext";

const NavBar = ({ logo, subtitle, title }) => {
  const { isDark } = useTheme();

  return (
    <nav className={`${styles.navbar} ${isDark ? styles.dark : styles.light}`}>
      <div className={styles.leftSection}>
        <img
          src={logo}
          alt="Logo"
          className={`${styles.logo} ${isDark ? "" : styles.logoLight}`}
        />
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.rightSection}>
        <div className={styles.spacer}></div>
        <ThemeToggle />
      </div>
    </nav>
  );
};

export default NavBar;
