import { visit } from "unist-util-visit";

export function remarkHeadingId() {
  return (tree) => {
    visit(tree, "heading", (node) => {
      const textContent = node.children
        .filter((child) => child.type === "text")
        .map((child) => child.value)
        .join("");
      node.data = node.data || {};
      node.data.hProperties = node.data.hProperties || {};
      node.data.hProperties.id = textContent.toLowerCase().replace(/\s+/g, "-");
    });
  };
}
