import React, { useState, useEffect, useRef } from "react";
import styles from "./CoolLandingPage.module.css";
import { Home } from "lucide-react";
import NavBar from "./NavBar";
import TableOfContents from "./TableOfContents";
import MarkdownDisplay from "./MarkDownDisplay";
import ScrollFooter from "./ScrollFooter";
import { useTheme } from "./ThemeContext";

const gradientOptions = {
  purple: styles.gradientPurple,
  blue: styles.gradientBlue,
  brown: styles.gradientBrown,
  green: styles.gradientGreen,
  yellow: styles.gradientYellow,
};

const CoolLandingPage = ({
  logo,
  title,
  description,
  subtitle,
  loadingTime = 2000,
  logoSize = "12rem",
  gradient = "purple",
  homeLink,
  markdownPath,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [contentVisible, setContentVisible] = useState(false);
  const [markdown, setMarkdown] = useState("");
  const { isDark } = useTheme();
  const contentRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => setContentVisible(true), 50);
    }, loadingTime);

    return () => clearTimeout(timer);
  }, [loadingTime]);

  useEffect(() => {
    if (markdownPath) {
      fetch(markdownPath)
        .then((response) => response.text())
        .then((text) => {
          setMarkdown(text);
        });
    }
  }, [markdownPath]);

  const gradientClass =
    gradientOptions[gradient.toLowerCase()] || gradientOptions.purple;

  const handleClick = (e) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      window.location.href = homeLink;
    }
  };

  const scrollToHeader = (headerText) => {
    if (contentRef.current) {
      const headers = contentRef.current.querySelectorAll(
        "h1, h2, h3, h4, h5, h6"
      );
      const targetHeader = Array.from(headers).find(
        (header) => header.textContent.trim() === headerText
      );

      if (targetHeader) {
        const offsetTop = targetHeader.offsetTop - 80; // Adjust for navbar height
        contentRef.current.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div
      className={`${styles.container} ${
        isLoading ? styles.containerLoading : gradientClass
      } ${isDark ? styles.dark : ""}`}
    >
      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}></div>
        </div>
      ) : (
        <>
          <NavBar logo={logo} subtitle={subtitle} title={title} />
          <div className={styles.mainContent}>
            <div className={styles.tocContainer}>
              <TableOfContents
                markdown={markdown}
                onHeaderClick={scrollToHeader}
              />
            </div>
            <div className={styles.contentContainer} ref={contentRef}>
              <div
                className={`${styles.content} ${
                  contentVisible ? styles.contentVisible : ""
                }`}
              >
                <div className={styles.contentShape}>
                  {markdownPath && <MarkdownDisplay filePath={markdownPath} />}
                  <div className={styles.homeLink}>
                    <p className={styles.description}>{description}</p>
                    <a href={homeLink} onClick={handleClick}>
                      <Home size={26} />
                      <span>Go back Home</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollFooter />
        </>
      )}
    </div>
  );
};

export default CoolLandingPage;
