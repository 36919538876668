import React, { useState, useEffect } from "react";
import styles from "./TableOfContents.module.css";
import { useTheme } from "./ThemeContext";

const TableOfContents = ({ markdown, onHeaderClick }) => {
  const [toc, setToc] = useState([]);
  const { isDark } = useTheme();

  useEffect(() => {
    const headers = markdown.match(/^#{1,6}.+$/gm) || [];
    const tocItems = headers.map((header) => {
      const level = header.match(/^#+/)[0].length;
      const title = header.replace(/^#+\s*/, "");
      return { level, title };
    });
    setToc(tocItems);
  }, [markdown]);

  return (
    <nav className={`${styles.toc} ${isDark ? styles.dark : styles.light}`}>
      <h2>Table of Contents</h2>
      <ul>
        {toc.map((item, index) => (
          <li key={index} className={styles[`level${item.level}`]}>
            <a
              href={`#${item.title.toLowerCase().replace(/\s+/g, "-")}`}
              onClick={(e) => {
                e.preventDefault();
                onHeaderClick(item.title);
              }}
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TableOfContents;
