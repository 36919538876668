import React from "react";
import styles from "./Footer.module.css";
import logoSvg from "./Monjo_White.png";
import { FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";
import { useTheme } from "./ThemeContext";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { isDark } = useTheme();

  return (
    <footer
      className={`${styles.footer} ${isDark ? styles.dark : styles.light}`}
    >
      <div className={styles.container}>
        {/* Top Section */}
        <div className={styles.topSection}>
          {/* Left Section */}
          <div className={styles.leftSection}>
            <div className={styles.logo}>
              <img
                src={logoSvg}
                alt="Monjo"
                className={`${styles.logoImage} ${
                  isDark ? "" : styles.logoLight
                }`}
              />
            </div>
            <div className={styles.socialIcons}>
              <a
                href="https://linkedin.com/company/monjo"
                className={styles.socialLink}
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://twitter.com/monjo"
                className={styles.socialLink}
                aria-label="Twitter"
              >
                <FaTwitter />
              </a>
              <a
                href="https://instagram.com/monjo"
                className={styles.socialLink}
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
            </div>
          </div>

          {/* Right Section Wrapper */}
          <div className={styles.rightSectionWrapper}>
            {/* Right Section */}
            <div className={styles.rightSection}>
              <div className={styles.linkColumn}>
                <div className={styles.columnTitle}>Company</div>
                <a href="https://monjo.co" className={styles.link}>
                  Home
                </a>
                <a href="https://monjo.co#ourprocess" className={styles.link}>
                  Our Process
                </a>
                <a href="https://monjo.co#ourwork" className={styles.link}>
                  Our Work
                </a>
              </div>
              <div className={styles.linkColumn}>
                <div className={styles.columnTitle}>Need Help?</div>
                <a href="https://monjo.co/contact" className={styles.link}>
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className={styles.bottomSection}>
          <div className={styles.legalColumn}>
            <p className={styles.legalText}>
              These Terms will be applied fully and affect to your use of this
              Website. By using this Website, you agreed to accept all terms and
              conditions written in here. You must not use this Website if you
              disagree with any of these Website Standard Terms and Conditions.
            </p>
          </div>
          <div className={styles.copyrightColumn}>
            <p className={styles.copyright}>
              {currentYear} Monjo.co | This is a Merchsalad Inc. Company
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
