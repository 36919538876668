import React, { createContext, useState, useEffect, useContext } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(true); // Set default to true for dark mode

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setIsDark(savedTheme === "dark");
    } else {
      // If no theme is saved, set it to dark by default
      localStorage.setItem("theme", "dark");
    }
    document.body.classList.toggle("dark", isDark);
  }, []);

  const toggleTheme = () => {
    setIsDark((prevDark) => {
      const newDark = !prevDark;
      localStorage.setItem("theme", newDark ? "dark" : "light");
      document.body.classList.toggle("dark", newDark);
      return newDark;
    });
  };

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
