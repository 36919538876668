import React from "react";
import { useTheme } from "./ThemeContext";
import { Moon, Sun } from "lucide-react";
import styles from "./ThemeToggle.module.css";

const ThemeToggle = () => {
  const { isDark, toggleTheme } = useTheme();

  return (
    <button
      onClick={toggleTheme}
      className={`${styles.toggleButton} ${
        isDark ? styles.dark : styles.light
      }`}
      aria-label={isDark ? "Switch to light mode" : "Switch to dark mode"}
    >
      {isDark ? <Sun size={20} /> : <Moon size={20} />}
    </button>
  );
};

export default ThemeToggle;
