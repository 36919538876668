import React from "react";
import { Helmet } from "react-helmet";
import CoolLandingPage from "./CoolLandingPage";
import logoWhite from "./Monjo_White.png";
import { ThemeProvider, useTheme } from "./ThemeContext";
import "./App.css";

const AppContent = () => {
  const { isDark } = useTheme();

  return (
    <div className={`app ${isDark ? "dark" : "light"}`}>
      <Helmet>
        <title>{process.env.REACT_APP_SUBTITLE}</title>
        <meta
          name="description"
          content={
            process.env.REACT_APP_HOME_LINK +
            " " +
            process.env.REACT_APP_SUBTITLE
          }
        />
      </Helmet>
      <CoolLandingPage
        logo={logoWhite}
        subtitle={process.env.REACT_APP_SUBTITLE}
        title={process.env.REACT_APP_TITLE}
        description={process.env.REACT_APP_DESCRIPTION}
        loadingTime={parseInt(process.env.REACT_APP_LOADING_TIME, 10)}
        logoSize={process.env.REACT_APP_LOGO_SIZE}
        gradient={process.env.REACT_APP_GRADIENT || "purple"}
        homeLink={process.env.REACT_APP_HOME_LINK}
        markdownPath={process.env.REACT_APP_MARKDOWN_PATH}
      />
    </div>
  );
};

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;
