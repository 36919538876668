import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import styles from "./ScrollFooter.module.css";

const ScrollFooter = () => {
  const [isVisible, setIsVisible] = useState(false);
  const footerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={footerRef}
      className={`${styles.scrollFooter} ${isVisible ? styles.visible : ""}`}
    >
      <Footer />
    </div>
  );
};

export default ScrollFooter;
