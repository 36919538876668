import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  vscDarkPlus,
  vs,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import { remarkHeadingId } from "./remarkHeadingId";
import { useTheme } from "./ThemeContext";
import styles from "./MarkDownDisplay.module.css";

const MarkdownDisplay = ({ filePath }) => {
  const [markdown, setMarkdown] = useState("");
  const { isDark } = useTheme();

  useEffect(() => {
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, [filePath]);

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={isDark ? vscDarkPlus : vs}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  return (
    <div
      className={`${styles.markdownContainer} ${
        isDark ? styles.dark : styles.light
      }`}
    >
      <ReactMarkdown
        children={markdown}
        remarkPlugins={[remarkGfm, remarkHeadingId]}
        components={components}
      />
    </div>
  );
};

export default MarkdownDisplay;
